var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.modalTitle,"okText":"Save"},on:{"ok":_vm.saveService},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-spin',{attrs:{"tip":"Please wait...","spinning":_vm.loading}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Title"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'title',
                {
                  rules: [{ required: true, message: 'Title is required!' }],
                  initialValue: _vm.service.title,
                },
              ]),expression:"[\n                'title',\n                {\n                  rules: [{ required: true, message: 'Title is required!' }],\n                  initialValue: service.title,\n                },\n              ]"}],attrs:{"placeholder":"Title","auto-size":""}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Description"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'description',
                {
                  rules: [
                    { required: true, message: 'Description is required!' },
                  ],
                  initialValue: _vm.service.description,
                },
              ]),expression:"[\n                'description',\n                {\n                  rules: [\n                    { required: true, message: 'Description is required!' },\n                  ],\n                  initialValue: service.description,\n                },\n              ]"}],attrs:{"placeholder":"Description","rows":5}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Price"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'price',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Price is required!',
                    },
                  ],
                  initialValue: _vm.service.price,
                },
              ]),expression:"[\n                'price',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Price is required!',\n                    },\n                  ],\n                  initialValue: service.price,\n                },\n              ]"}],attrs:{"formatter":_vm.formattedPrice}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.isTourPackage)?_c('a-form-item',{attrs:{"label":""}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'enable_additional_fields',
                {
                  valuePropName: 'checked',
                  initialValue: _vm.service.enable_additional_fields,
                },
              ]),expression:"[\n                'enable_additional_fields',\n                {\n                  valuePropName: 'checked',\n                  initialValue: service.enable_additional_fields,\n                },\n              ]"}],on:{"change":_vm.onAdditionalFieldsChanged}},[_vm._v(" Enable Additional Fields ")])],1):_vm._e()],1)]),(_vm.isTourPackage && _vm.service.enable_additional_fields)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Available Tickets","id":"available_tickets"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'available_tickets',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Available tickets is required!',
                    },
                  ],
                  initialValue: _vm.service.available_tickets,
                },
              ]),expression:"[\n                'available_tickets',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Available tickets is required!',\n                    },\n                  ],\n                  initialValue: service.available_tickets,\n                },\n              ]"}],attrs:{"parser":(value) => value.replace(/\₵\s?|(,*)/g, '')}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"id":"departure_date","label":"Departure Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'departure_date',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Departure date is required!',
                    },
                  ],
                  initialValue: _vm.service.departure_date || new Date(),
                },
              ]),expression:"[\n                'departure_date',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Departure date is required!',\n                    },\n                  ],\n                  initialValue: service.departure_date || new Date(),\n                },\n              ]"}],staticClass:"mb-2"})],1)],1)]):_vm._e(),(_vm.isTourPackage && _vm.service.enable_additional_fields)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"id":"arrival_date","label":"Arrival Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'arrival_date',
                {
                  rules: [
                    { required: true, message: 'Arrival date is required!' },
                  ],
                  initialValue: _vm.service.arrival_date,
                },
              ]),expression:"[\n                'arrival_date',\n                {\n                  rules: [\n                    { required: true, message: 'Arrival date is required!' },\n                  ],\n                  initialValue: service.arrival_date,\n                },\n              ]"}],staticClass:"mb-2"})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"id":"departure_time","label":"Departure Time"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'departure_time',
                {
                  rules: [
                    { required: true, message: 'Arrival date is required!' },
                  ],
                  initialValue: _vm.service.departure_time,
                },
              ]),expression:"[\n                'departure_time',\n                {\n                  rules: [\n                    { required: true, message: 'Arrival date is required!' },\n                  ],\n                  initialValue: service.departure_time,\n                },\n              ]"}],attrs:{"format":"HH:mm","valueFormat":"HH:mm"}})],1)],1)]):_vm._e(),(_vm.isTourPackage && _vm.service.enable_additional_fields)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"id":"arrival_time","label":"Arrival Time"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'arrival_time',
                {
                  rules: [
                    { required: true, message: 'Arrival time is required!' },
                  ],
                  initialValue: _vm.service.arrival_time,
                },
              ]),expression:"[\n                'arrival_time',\n                {\n                  rules: [\n                    { required: true, message: 'Arrival time is required!' },\n                  ],\n                  initialValue: service.arrival_time,\n                },\n              ]"}],attrs:{"format":"HH:mm","valueFormat":"HH:mm"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"id":"package_expiry","label":"Expiry Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'package_expiry',
                {
                  rules: [
                    { required: true, message: 'Expiry date is required!' },
                  ],
                  initialValue: _vm.service.package_expiry || new Date(),
                },
              ]),expression:"[\n                'package_expiry',\n                {\n                  rules: [\n                    { required: true, message: 'Expiry date is required!' },\n                  ],\n                  initialValue: service.package_expiry || new Date(),\n                },\n              ]"}],staticClass:"mb-2"})],1)],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-upload',{attrs:{"list-type":"picture-card","accept":"image/*","listType":"picture-card","multiple":false,"before-upload":_vm.beforeFeaturedImageUpload,"fileList":_vm.fileList,"showUploadList":{
              showRemoveIcon: false,
            }},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : _vm.hasUploadedImage ? 'redo' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.hasUploadedImage ? "Change Image" : "Featured Image")+" ")])],1)]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }