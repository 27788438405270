<template>
  <div>
    <div class="service-header">
      <div class="font-size-36 font-weight-bold text-dark mb-4">Services</div>
      <a-dropdown
        class="ml-4"
        v-if="canAddService && canAddTravelAndTourPackage"
      >
        <a
          class="font-size-16 kit__utils__link"
          @click="(e) => e.preventDefault()"
        >
          Add Service
        </a>
        <a-menu slot="overlay">
          <a-menu-item key="0" @click="addService('travel')">
            <a href="javascript: void(0);" class="font-size-16 kit__utils__link"
              >Travel Ticket</a
            >
          </a-menu-item>
          <a-menu-item key="1" @click="addService('tour')">
            <a href="javascript: void(0);" class="font-size-16 kit__utils__link"
              >Tour Package</a
            >
          </a-menu-item>
          <a-menu-divider />
        </a-menu>
      </a-dropdown>

      <a-button
        type="primary"
        @click="addService()"
        v-else-if="canAddService && !canAddTravelAndTourPackage"
        >Add Service</a-button
      >
    </div>

    <div class="row">
      <div v-if="servicesData.length">
        <div class="flex-wrapper">
          <shared-service-card-with-featured-image
            class="entity-card"
            v-for="(service, index) of servicesData"
            :key="index"
            :service="service"
            @editService="editService($event)"
            @removeService="deleteService($event)"
          />
        </div>
      </div>
      <div v-else class="text-center">
        <h4>No Services found</h4>
      </div>
    </div>

    <tour-operator-service-modal
      v-if="showModal"
      :defaultService="defaultService"
      :showModal="showModal"
      @closeModal="closeModal"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { get } from "lodash";
import { addMonths } from "date-fns";
import moment from "moment";

import ProfileMixin from "@/mixins/Profile";
import SharedServiceCardWithFeaturedImage from "@/components/services/SharedServiceCardWithFeaturedImage.vue";
import TourOperatorServiceModal from "@/components/services/TourOperatorServiceModal.vue";

export default {
  mixins: [ProfileMixin],
  components: {
    SharedServiceCardWithFeaturedImage,
    TourOperatorServiceModal,
  },
  data() {
    return {
      defaultService: {},
      showModal: false,
      dateFormat: "YYYY-MM-DD",
    };
  },
  computed: {
    ...mapGetters("media", ["getMediaUrl"]),
    ...mapGetters("auth", ["currentUserType", "currentUser"]),
    ...mapGetters("touroperator", ["tourOperatorMedia", "services"]),
    servicesData() {
      return this.services
        .filter((service) => service.status === "active")
        .reverse();
    },
    permissions() {
      return get(this.currentUser, "permissions", {});
    },
    canAddService() {
      return get(this.permissions, "can_add_service", false);
    },
    canUpdateService() {
      return get(this.permissions, "can_update_service", false);
    },
    canRemoveService() {
      return get(this.permissions, "can_remove_service", false);
    },
    media() {
      return this.tourOperatorMedia;
    },
    canAddTravelAndTourPackage() {
      const manager = this.account;
      const entity_type = get(manager, "information.entity_type", "");
      return entity_type === "travel_and_tour";
    },
  },
  methods: {
    async saveService(service) {
      delete service.status;

      const services = await this.updateProfileService(service);
      if (services && services.length) {
        this.servicesData = services;
      }

      this.closeModal();
    },
    async deleteService(service) {
      try {
        this.loading = true;

        await this.deleteProfileService(service._id);
        this.closeModal();
      } catch (error) {
        this.$notification.error({
          message:
            "There was an error deleting this service. Please contact support if issue persists",
        });
      } finally {
        this.loading = false;
      }
    },
    editService(service) {
      this.defaultService = service;
      this.showModal = true;
    },
    addService(service_type) {
      if (service_type) {
        this.defaultService = {
          title: "",
          description: "",
          price: 0,
          service_type,
        };
      } else {
        const manager = this.account;
        const entity_type = get(manager, "information.entity_type", "");
        this.defaultService = {
          title: "",
          description: "",
          price: 0,
          service_type: entity_type,
        };
      }

      if (this.defaultService.service_type === "tour") {
        this.defaultService = {
          ...this.defaultService,
          departure_date: moment(new Date(), this.dateFormat),
          departure_time: "",
          arrival_time: "",
          arrival_date: moment(addMonths(new Date(), 1), this.dateFormat),
          available_tickets: 0,
          package_expiry: moment(addMonths(new Date(), 1), this.dateFormat),
        };
      }

      this.showModal = true;
    },
    closeModal() {
      this.defaultService = {};
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.service-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  align-self: auto;
  flex-wrap: wrap;
}

.entity-card {
  margin-right: 25px;
}
</style>
