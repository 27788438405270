<template>
  <a-modal
    v-model="visible"
    :title="modalTitle"
    @ok="saveService"
    okText="Save"
  >
    <a-spin tip="Please wait..." :spinning="loading">
      <a-form :form="form">
        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Title">
              <a-input
                placeholder="Title"
                auto-size
                v-decorator="[
                  'title',
                  {
                    rules: [{ required: true, message: 'Title is required!' }],
                    initialValue: service.title,
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Description">
              <a-textarea
                placeholder="Description"
                :rows="5"
                v-decorator="[
                  'description',
                  {
                    rules: [
                      { required: true, message: 'Description is required!' },
                    ],
                    initialValue: service.description,
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Price">
              <a-input-number
                :formatter="formattedPrice"
                v-decorator="[
                  'price',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Price is required!',
                      },
                    ],
                    initialValue: service.price,
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <a-form-item label="" v-if="isTourPackage">
              <a-checkbox
                v-decorator="[
                  'enable_additional_fields',
                  {
                    valuePropName: 'checked',
                    initialValue: service.enable_additional_fields,
                  },
                ]"
                @change="onAdditionalFieldsChanged"
              >
                Enable Additional Fields
              </a-checkbox>
            </a-form-item>
          </div>
        </div>

        <div
          class="row"
          v-if="isTourPackage && service.enable_additional_fields"
        >
          <div class="col-md-6">
            <a-form-item label="Available Tickets" id="available_tickets">
              <a-input-number
                v-decorator="[
                  'available_tickets',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Available tickets is required!',
                      },
                    ],
                    initialValue: service.available_tickets,
                  },
                ]"
                :parser="(value) => value.replace(/\₵\s?|(,*)/g, '')"
              />
            </a-form-item>
          </div>

          <div class="col-md-6">
            <a-form-item id="departure_date" label="Departure Date">
              <a-date-picker
                class="mb-2"
                v-decorator="[
                  'departure_date',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Departure date is required!',
                      },
                    ],
                    initialValue: service.departure_date || new Date(),
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div
          class="row"
          v-if="isTourPackage && service.enable_additional_fields"
        >
          <div class="col-md-6">
            <a-form-item id="arrival_date" label="Arrival Date">
              <a-date-picker
                class="mb-2"
                v-decorator="[
                  'arrival_date',
                  {
                    rules: [
                      { required: true, message: 'Arrival date is required!' },
                    ],
                    initialValue: service.arrival_date,
                  },
                ]"
              />
            </a-form-item>
          </div>

          <div class="col-md-6">
            <a-form-item id="departure_time" label="Departure Time">
              <a-time-picker
                format="HH:mm"
                valueFormat="HH:mm"
                v-decorator="[
                  'departure_time',
                  {
                    rules: [
                      { required: true, message: 'Arrival date is required!' },
                    ],
                    initialValue: service.departure_time,
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div
          class="row"
          v-if="isTourPackage && service.enable_additional_fields"
        >
          <div class="col-md-6">
            <a-form-item id="arrival_time" label="Arrival Time">
              <a-time-picker
                format="HH:mm"
                valueFormat="HH:mm"
                v-decorator="[
                  'arrival_time',
                  {
                    rules: [
                      { required: true, message: 'Arrival time is required!' },
                    ],
                    initialValue: service.arrival_time,
                  },
                ]"
              />
            </a-form-item>
          </div>

          <div class="col-md-6">
            <a-form-item id="package_expiry" label="Expiry Date">
              <a-date-picker
                class="mb-2"
                v-decorator="[
                  'package_expiry',
                  {
                    rules: [
                      { required: true, message: 'Expiry date is required!' },
                    ],
                    initialValue: service.package_expiry || new Date(),
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <a-upload
              list-type="picture-card"
              accept="image/*"
              listType="picture-card"
              :multiple="false"
              :before-upload="beforeFeaturedImageUpload"
              :fileList="fileList"
              :showUploadList="{
                showRemoveIcon: false,
              }"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div>
                <a-icon
                  :type="
                    loading ? 'loading' : hasUploadedImage ? 'redo' : 'plus'
                  "
                />
                <div class="ant-upload-text">
                  {{ hasUploadedImage ? "Change Image" : "Featured Image" }}
                </div>
              </div>
            </a-upload>

            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </div>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { cloneDeep, get } from "lodash";
import { mapActions } from "vuex";
import { addMonths } from "date-fns";
import moment from "moment";

import { DELETE_SERVICE_FEATURED_MEDIA } from "@/store/actions";
import ProfileMixin from "@/mixins/Profile";

export default {
  mixins: [ProfileMixin],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    defaultService: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      previewVisible: false,
      previewImage: "",

      service: {
        title: "",
        description: "",
        price: "",
        service_type: "tour",
        enable_additional_fields: false,
        departure_date: moment(new Date(), "YYYY-MM-DD"),
        departure_time: "",
        arrival_time: "",
        arrival_date: moment(addMonths(new Date(), 1), "YYYY-MM-DD"),
        available_tickets: 0,
        package_expiry: moment(addMonths(new Date(), 1), "YYYY-MM-DD"),
      },

      fileList: [],

      // for validation
      form: this.$form.createForm(this, { name: "addService" }),
      featuredImage: null,
    };
  },
  computed: {
    featuredImageData() {
      const { featured_image, id } = this.service;
      if (featured_image && featured_image.path) {
        return [
          {
            uid: id,
            name: featured_image.name || "",
            url: featured_image.path || "",
          },
        ];
      }
      return [];
    },
    isEdit() {
      return !!Object.keys(this.service).length;
    },
    modalTitle() {
      return `${this.isEdit ? "Edit" : "Add"} Service`;
    },
    hasUploadedImage() {
      return this.featuredImageData.length > 0 || this.featuredImage;
    },
    isTourPackage() {
      return get(this.service, "service_type") === "tour";
    },
  },
  watch: {
    showModal: {
      handler(show) {
        this.visible = show;
      },
      immediate: true,
    },
    defaultService: {
      handler(service) {
        this.service = service;
      },
      immediate: true,
    },
    visible(show) {
      this.$emit("closeModal", show);
    },
    featuredImageData: {
      handler(value) {
        if (value.length > 0) {
          this.fileList.push(...value);
        }
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    if (this.isEdit) {
      this.service = cloneDeep(this.defaultService);
    }
  },
  methods: {
    ...mapActions("media", {
      deleteFeaturedImage: DELETE_SERVICE_FEATURED_MEDIA,
    }),
    handleCancel() {
      this.previewVisible = false;
    },
    beforeFeaturedImageUpload(file) {
      this.featuredImage = file;
      return false;
    },
    saveService(e) {
      this.form.validateFields((err, values) => {
        if (err) {
          this.$notification.error({ message: "Please correct form errors." });
          return false;
        }
        this.addOrUpdateService(values);
      });
    },
    onAdditionalFieldsChanged(e) {
      const status = e.target.checked;
      this.service.enable_additional_fields = status;
    },
    async handleChange(info) {
      const file = info.file;
      file.url = await this.getBase64(file);

      this.fileList = [];
      this.fileList.push(info.file);

      this.featuredImage = file;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    async addOrUpdateService(values) {
      try {
        this.loading = true;

        const formData = new FormData();

        const { _id, service_type } = this.service;

        let update_payload = {};

        if (service_type === "tour") {
          update_payload = {
            service_type,
            ...values,
          };
        } else {
          update_payload = {
            service_type,
            ...values,
          };
        }

        if (_id) {
          update_payload._id = _id;
        }

        if (this.featuredImage) {
          update_payload.featured_image = this.featuredImage;
        }

        Object.keys(update_payload).forEach((key) => {
          formData.append(key, update_payload[key]);
        });

        await this.updateProfileService(formData);

        this.visible = false;
      } catch (error) {
        console.log("save or update error", error);
      } finally {
        this.loading = false;
      }
    },
    async handleRemove(data) {
      try {
        if (data.uid) {
          await this.deleteFeaturedImage({ service_id: data.uid });
        }
      } catch (e) {
        console.error(e);
      }
      this.featuredImage = null;
    },
  },
};
</script>
